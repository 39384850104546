import { inject, Injectable } from '@angular/core';
import { LoggableAPI } from '../models/protocols/loggable-api';
import { ApiClient } from './api-client';
import { Endpoints } from './endpoints';
import { CreateMemberRequest } from '../models/account/requests/create-member-request';
import { Observable, throwError } from 'rxjs';
import { Deserializable } from '../models/protocols/deserializable';
import { catchError, map } from 'rxjs/operators';
import { CustomError } from '../models/shared/custom-error';
import { MemberUser } from '../models/account/dto/member-user';
import { MemberOnboardingInfoRequest } from '../models/account/requests/member-onboarding-info-request';
import { ODataQueryOptions } from '../models/shared/odata-query-options';
import { ChangeEmailRequest } from '../models/account/requests/change-email-request';
import { HttpResponse } from '@angular/common/http';
import { ODataResponse } from '../models/protocols/odata-response';
import { MemberUserSearchResult } from '../models/account/dto/member-user-search-result';
import { MemberRecordCommentRequest } from '../models/account/requests/member-record-comment-request';
import { MemberRecordComment } from '../views/records/components/members/view-member-record/member-record-comments/member-record-comment';
import { Mentionable } from '../models/base/mentionable';
import { EmploymentHistory } from '../models/account/dto/employment-history';
import { ResendMemberInviteRequest } from '../models/account/requests/resend-member-invite-request';
import { MostRecentInvite } from '../models/account/dto/most-recent-invite';

@Injectable({
  providedIn: 'root'
})
export class MembersAPI implements LoggableAPI {
  private apiClient = inject(ApiClient);

  constructor() {}

  public serviceName = 'Members';

  public createMember(req: CreateMemberRequest, employerId: string): Observable<MemberUser> {
    const url = Endpoints.addMember(employerId);
    return this.apiClient.postObj<MemberUser, Deserializable>(MemberUser, url, req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public createMemberWithoutEmployer(req: CreateMemberRequest): Observable<MemberUser> {
    const url = Endpoints.addMemberWithoutEmployer();
    return this.apiClient.postObj<MemberUser, Deserializable>(MemberUser, url, req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public updateMemberOnboardingInformation(req: MemberOnboardingInfoRequest): Observable<MemberUser> {
    const url = Endpoints.updateMemberOnboardingInformation(req.id.toString());
    return this.apiClient.putObj<MemberUser, Deserializable>(MemberUser, url, req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public getMemberCommentMentionables(opts: ODataQueryOptions): Observable<ODataResponse<Mentionable>> {
    const url = Endpoints.getMemberCommentMentionables();
    return this.apiClient.getOdata<Mentionable>(url, Mentionable, opts).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public getMemberById(memberId: string, odataQueryOptions: ODataQueryOptions): Observable<MemberUser> {
    const url = Endpoints.getMemberById(memberId);
    return this.apiClient.getOdataObj<MemberUser>(url, MemberUser, odataQueryOptions).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public forceChangeEmail(u: MemberUser, req: ChangeEmailRequest): Observable<MemberUser> {
    const url = Endpoints.memberForceChangeEmail(u?.id.toString(10));
    return this.apiClient.postObj<MemberUser, Deserializable>(MemberUser, url, req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public forceChangePassword(u: MemberUser): Observable<HttpResponse<any>> {
    const url = Endpoints.memberForceChangePassword(u?.id.toString(10));
    return this.apiClient.simpleBodylessPost(url).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public searchMembersByIdOrName(
    odataQueryOptions: ODataQueryOptions
  ): Observable<ODataResponse<MemberUserSearchResult>> {
    const url = Endpoints.searchMembersByIdOrName();
    return this.apiClient.getOdata<MemberUserSearchResult>(url, MemberUserSearchResult, odataQueryOptions).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public getAllMembers(odataQueryOptions: ODataQueryOptions): Observable<ODataResponse<MemberUser>> {
    const url = Endpoints.getAllMembers();
    return this.apiClient.getOdata<MemberUser>(url, MemberUser, odataQueryOptions).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public addCommentToMemberRecord(req: MemberRecordCommentRequest): Observable<MemberRecordComment[]> {
    const url = Endpoints.memberComments(req.memberId);
    return this.apiClient.simplePost(url, req).pipe(
      map(res => {
        const comments = window?.injector?.Deserialize?.arrayOf(MemberRecordComment, res.body);
        return comments.sort((a, b) => {
          // @ts-ignore
          return new Date(b.createdDate) - new Date(a.createdDate);
        });
      }),
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public updateMemberEmploymentHistory(req: EmploymentHistory): Observable<EmploymentHistory> {
    const url = Endpoints.updateEmploymentHistory(req.memberId.toString(), req.id.toString());
    return this.apiClient.putObj<EmploymentHistory, Deserializable>(EmploymentHistory, url, req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public createEmployerHistory(req: EmploymentHistory, memberId: string): Observable<EmploymentHistory> {
    const url = Endpoints.createEmploymentHistory(memberId);
    return this.apiClient.postObj<EmploymentHistory, Deserializable>(EmploymentHistory, url, req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public getEmployerHistoryForMember(
    memberId: string,
    odataQueryOptions: ODataQueryOptions
  ): Observable<EmploymentHistory[]> {
    const url = Endpoints.getEmploymentHistoryForMember(memberId);
    return this.apiClient
      .getOdata<EmploymentHistory>(url, EmploymentHistory, odataQueryOptions, undefined, undefined, true)
      .pipe(
        map(res => res?.value),
        catchError(e => {
          const err = new CustomError(e, this.serviceName);
          return throwError(() => err);
        })
      );
  }

  public resendMemberInvite(memberId: string, req: ResendMemberInviteRequest): Observable<MostRecentInvite> {
    const url = Endpoints.resendMemberInvite(memberId);
    return this.apiClient.postObj<MostRecentInvite, Deserializable>(MostRecentInvite, url, req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }
}
