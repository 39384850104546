<div
  class="alert-container"
  [style.background-color]="backgroundColour$ | async"
  [style.border-color]="accentColour$ | async">
  <div class="alert-body">
    <div class="alert-title" [style.color]="titleColour$ | async">{{ title$ | async }}</div>
    <div class="alert-description-container">
      <div class="alert-description" [style.color]="textColour$ | async">
        {{ description$ | async }}
      </div>
      <div class="learn-more-button" (click)="learnMoreClicked.emit()" i18n>Learn more</div>
    </div>
    <div class="dismiss-button-container">
      <img
        *ngIf="isDismissable$ | async"
        (click)="dismissClicked.emit()"
        class="dismiss-button"
        [src]="dismissButtonSrc$ | async"
        alt="Dismiss" />
    </div>
  </div>
</div>
