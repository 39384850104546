<lib-reactive-form-group
  #form
  [bindTo]="req$ | async"
  [applyDisabledStylesToAllElements]="true"
  [includeIconWithError]="true"
  (formSubmitted$)="submitContactInfo($event)"
  [atLeastNItemsFilledIn]="1">
  <lib-reactive-form-column-layout [nColumns]="(isMobile$ | async) ? 1 : 2" [columnGap]="'2rem'">
    <lib-reactive-form-phone-country-code
      [bindingProperty]="'primaryPhoneNumber'"
      [countryCodeBindingProperty]="'primaryPhoneNumberCountryCode'"
      [countryCodes]="(countryCodes$ | async) ?? []"
      label="Primary Phone Number"
      i18n-label
      [required]="true"
      [inputName]="'primaryPhoneNumber'"></lib-reactive-form-phone-country-code>
    <lib-reactive-form-phone-country-code
      [bindingProperty]="'secondaryPhoneNumber'"
      [countryCodeBindingProperty]="'secondaryPhoneNumberCountryCode'"
      [countryCodes]="(countryCodes$ | async) ?? []"
      label="Secondary Phone Number"
      i18n-label
      hintText="Optional"
      i18n-hintText
      [inputName]="'secondaryPhoneNumber'">
    </lib-reactive-form-phone-country-code>
  </lib-reactive-form-column-layout>
  <ng-content></ng-content>
  <lib-reactive-form-file-uploader
    *ngIf="filesRequired"
    #fileUploader
    (previewClicked)="openFileInNewTab($event)"
    (duplicateFileNamesAdded)="duplicateFilesAdded()"
    [inputName]="'file'"
    [id]="'file'"
    [showSelectedFileIcon]="true"
    [accept]="acceptedFileTypes"
    label="Document"
    i18n-label
    uploadTitle="Upload a file"
    i18n-uploadTitle
    [showPreview]="true"
    [multiple]="allowMultipleFiles"
    [maxFileSizeInMb]="maxFileSizeInMb"
    [maxTotalFileSizeInMb]="allowMultipleFiles ? maxTotalFileSizeInMb : 0"
    [maxFiles]="5"
    uploadBody="or drag and drop .png or .jpeg up to 3MB"
    i18n-uploadBody
    [chooseAnother]="true"
    [required]="true"
    [chooseAnotherLabel]="chooseAnotherLabel"
    previewLabel="Preview"
    i18n-previewLabel
    removeLabel="Remove"
    i18n-removeLabel>
  </lib-reactive-form-file-uploader>
</lib-reactive-form-group>
