export enum SubmissionStatusPrimaryColorEnum {
  Pending = '#B24819',
  Submitted = '#5D5B24',
  InReview = '#B24819',
  Complete = '#395200',
  Cancelled = '#3B3B3C'
}

export enum SubmissionStatusSecondaryColorEnum {
  Pending = '#FFEAD1',
  Submitted = '#FAFADC',
  InReview = '#FFEAD1',
  Complete = '#E8F7D9',
  Cancelled = '#FAFAFA'
}

/* eslint-disable @typescript-eslint/naming-convention */
export const SubmissionStatusPrimaryColorMap: Record<number, string> = {
  1: SubmissionStatusPrimaryColorEnum.Pending,
  2: SubmissionStatusPrimaryColorEnum.Submitted,
  3: SubmissionStatusPrimaryColorEnum.InReview,
  4: SubmissionStatusPrimaryColorEnum.Complete,
  5: SubmissionStatusPrimaryColorEnum.Cancelled
};

export const SubmissionStatusSecondaryColorMap: Record<number, string> = {
  1: SubmissionStatusSecondaryColorEnum.Pending,
  2: SubmissionStatusSecondaryColorEnum.Submitted,
  3: SubmissionStatusSecondaryColorEnum.InReview,
  4: SubmissionStatusSecondaryColorEnum.Complete,
  5: SubmissionStatusSecondaryColorEnum.Cancelled
};
/* eslint-enable @typescript-eslint/naming-convention */
