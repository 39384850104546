import { AfterContentInit, ChangeDetectionStrategy, Component, forwardRef, Input } from '@angular/core';
import { CdkStepper } from '@angular/cdk/stepper';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: CdkStepper, useExisting: forwardRef(() => StepperComponent) }]
})
export class StepperComponent extends CdkStepper implements AfterContentInit {
  @Input() public showSteps = true;
}
