import { BaseViewModel } from '../../../../models/base/base-view-model';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { LoadingOptions } from '../../../../models/shared/loading-options';
import { NavItem } from '../nav-items/nav-item/models/nav-item';
import { DefaultNavItem } from '../nav-items/nav-item/models/default-nav-item';
import { NavItemId } from '../../../../models/enum/shared/nav-item-id.enum';
import { NavigationEnd, Router } from '@angular/router';
import { inject, Injectable } from '@angular/core';
import { ScreenService } from '../../../../services/screen-service.service';
import { UserDomainModel } from '../../../../domainModels/user-domain-model';
import { PortalService } from '../../../../services/portal/portal.service';
import { PortalType } from '../../../../models/enum/shared/portal-type';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { DropDownNavItem } from '../nav-items/nav-item/models/drop-down-nav-item';
import { NavSubItem } from '../nav-items/nav-sub-item/models/nav-sub-item';
import { PermissionService } from '../../../../services/permission-service';

@Injectable({ providedIn: 'root' })
export class NavbarViewModel extends BaseViewModel {
  constructor(private router: Router) {
    super();
    this.init();
  }

  private screenService = inject(ScreenService);
  private userDomainModel = inject(UserDomainModel);
  private portalService = inject(PortalService);
  private permissionService = inject(PermissionService);

  public _loadingOpts = new BehaviorSubject(LoadingOptions.default(false, true));
  public loadingOpts$ = this._loadingOpts as Observable<LoadingOptions>;
  public isLoading$ = this.loadingOpts$.pipe(map(opts => opts.isLoading));

  public navItems$ = combineLatest([this.portalService.portalType$, this.permissionService.permissionMapReady$]).pipe(
    switchMap(([pt, mapReady]) => {
      if (mapReady) {
        switch (pt) {
          case PortalType.Employer:
            return this.getEmployerNavItems();
          case PortalType.Member:
            return this.getMemberNavItems();
          case PortalType.Internal:
            return this.getInternalUserNavItems();
          default:
            return of([]);
        }
      } else {
        return of([]);
      }
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private _isNavbarCollapsed = new BehaviorSubject(true);
  public readonly isNavbarCollapsed$ = this._isNavbarCollapsed as Observable<boolean>;

  public isMobile$ = this.screenService.isMobile$;

  public init() {
    super.init();
    const lm = $localize`Loading...`;
    this._loadingOpts.addRequest(lm);
    this.permissionService.permissionMapReady$.subscribeWhileAlive({
      owner: this,
      next: ready => {
        if (ready) {
          this._loadingOpts.removeRequest(lm);
        }
      }
    });

    this.router.events.subscribeWhileAlive({
      owner: this,
      next: events => {
        if (events instanceof NavigationEnd) {
          this._isNavbarCollapsed.next(true);
        }
      }
    });
  }

  private getEmployerNavItems(): Observable<NavItem[]> {
    return this.userDomainModel.user$.pipe(
      map(u => {
        const navItems = [];
        navItems.push(
          this.getHomeNavItem(),
          this.getEmployersNavItem(),
          this.getEmployeesNavItem(),
          this.getRemittanceNavItem(),
          this.getDocumentsNavItem(),
          this.getMessagingNavItem(),
          this.getSettingsNavItem(),
          this.getProfileNavItem(u.fullName)
        );
        return navItems;
      })
    );
  }

  private getMemberNavItems(): Observable<NavItem[]> {
    return this.userDomainModel.user$.pipe(
      map(u => {
        const navItems = [];
        navItems.push(
          this.getHomeNavItem(),
          this.getAccountsNavItem(),
          this.getDocumentsNavItem(true),
          this.getContactUsNavItem(),
          this.getProfileNavItem(u.fullName)
        );
        return navItems;
      })
    );
  }

  private getInternalUserNavItems(): Observable<NavItem[]> {
    return combineLatest([
      this.userDomainModel.user$,
      this.permissionService.permissionGranted$([18]),
      this.permissionService.permissionGranted$([42]),
      this.permissionService.permissionGranted$([1]),
      this.permissionService.permissionGranted$([14])
    ]).pipe(
      map(([u, canViewSubmissions, canViewAlerts, canViewInternalUsers, canViewRoles]) => {
        const navItems = [];
        navItems.push(this.getHomeNavItem());
        if (canViewSubmissions) navItems.push(this.getSubmissionsNavItem());
        navItems.push(this.getRecordsDropdownNavItem());
        navItems.push(this.getMessagingNavItem());
        if (canViewAlerts || canViewInternalUsers || canViewRoles) {
          navItems.push(
            this.getInternalUserSettingsDropdownNavItems(canViewAlerts, canViewInternalUsers, canViewRoles)
          );
        }
        navItems.push(this.getProfileNavItem(u.fullName));
        return navItems;
      })
    );
  }

  private getHomeNavItem(): NavItem {
    return new DefaultNavItem(NavItemId.Home, $localize`Home`, [], '/dashboard', '', '');
  }

  private getAccountsNavItem(): NavItem {
    return new DefaultNavItem(NavItemId.Accounts, $localize`My Account`, [], '/accounts', '', '');
  }

  private getEmployeesNavItem(): NavItem {
    return new DefaultNavItem(NavItemId.Members, $localize`Employees`, [], '/records/employees', '', '');
  }

  private getEmployersNavItem(): NavItem {
    return new DefaultNavItem(NavItemId.Employers, $localize`Employer Profile`, [], '/employers', '', '');
  }

  private getRemittanceNavItem(): NavItem {
    return new DefaultNavItem(NavItemId.Remittance, $localize`Remittance`, [], '/remittance', '', '');
  }

  private getSettingsNavItem(): NavItem {
    return new DropDownNavItem(
      NavItemId.Settings,
      $localize`Settings`,
      [new NavSubItem($localize`Employer Users`, 'employer-users')],
      '/settings',
      '',
      ''
    );
  }

  private getDocumentsNavItem(memberDocuments: boolean = false): NavItem {
    const label = memberDocuments ? $localize`My Documents` : $localize`Documents`;
    return new DefaultNavItem(NavItemId.Documents, label, [], '/documents', '', '');
  }

  private getMessagingNavItem(): NavItem {
    return new DefaultNavItem(NavItemId.Messaging, $localize`Messaging`, [], '/messaging', '', '');
  }

  private getInternalUserSettingsDropdownNavItems(
    canViewAlerts: boolean,
    canViewInternalUsers: boolean,
    canViewRoles: boolean
  ): NavItem {
    const subItems: NavSubItem[] = [];
    if (canViewRoles) {
      const rolesSubItem = new NavSubItem($localize`Roles`, '');
      rolesSubItem.subItemFragment = 'roles';
      subItems.push(rolesSubItem);
    }

    if (canViewAlerts) subItems.push(new NavSubItem($localize`Alerts`, 'alerts'));
    if (canViewInternalUsers) subItems.push(new NavSubItem($localize`Internal Users`, 'internal-users'));

    // TODO: Add permission check when available
    subItems.push(new NavSubItem($localize`Release Notes`, 'release-notes'));

    return new DropDownNavItem(
      NavItemId.Settings,
      $localize`Settings`,
      subItems,
      '/settings',
      '',
      '',
      '/settings/menu'
    );
  }

  private getRecordsDropdownNavItem(): NavItem {
    return new DropDownNavItem(
      NavItemId.Records,
      $localize`Records`,
      [new NavSubItem($localize`Members`, 'members'), new NavSubItem($localize`Employers`, 'employers')],
      '/records',
      '',
      ''
    );
  }

  private getSubmissionsNavItem(): NavItem {
    return new DropDownNavItem(
      NavItemId.Work,
      $localize`Work`,
      [new NavSubItem($localize`Submissions`, 'submissions')],
      '/work',
      '',
      ''
    );
  }

  private getContactUsNavItem(): NavItem {
    return new DefaultNavItem(NavItemId.ContactUs, $localize`Contact Us`, [], '/contact-us', '', '');
  }

  private getProfileNavItem(userName: string): NavItem {
    const subItems: NavSubItem[] = [];
    const profileSubItem = new NavSubItem($localize`View Profile`, '', 'assets/nav/profile-icon.svg');
    profileSubItem.subItemFragment = 'memberOverview';
    subItems.push(profileSubItem);
    subItems.push(new NavSubItem($localize`Sign Out`, '/sign-out', 'assets/nav/sign-out-icon.svg', true));
    return new DropDownNavItem(
      NavItemId.Profile,
      userName,
      subItems,
      '/profile',
      'assets/nav/profile-icon.svg',
      'assets/nav/profile-icon-filled.svg',
      '/profile/view'
    );
  }

  public navigateToRoot() {
    this.router.navigate(['']).then(() => {});
  }

  public toggleNavbarCollapse() {
    this._isNavbarCollapsed.once(collapse => {
      this._isNavbarCollapsed.next(!collapse);
    });
  }
}
