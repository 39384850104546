import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { DispersedFormGroupService, ReactiveFormItemComponent } from '@csspension/reactive-form';
import { Observable } from 'rxjs';
import { AsyncValidatorFn, ValidatorFn, Validators } from '@angular/forms';
import { EmployerSearchResult } from '../../../../models/shared/employer-search-result';

@Component({
  selector: 'app-employer-search-input',
  templateUrl: './employer-search-input.component.html',
  styleUrls: ['./employer-search-input.component.scss'],
  providers: [
    {
      provide: ReactiveFormItemComponent,
      useExisting: forwardRef(() => EmployerSearchInputComponent)
    }
  ]
})
export class EmployerSearchInputComponent extends ReactiveFormItemComponent implements OnChanges {
  constructor(elementRef: ElementRef, dispersedFormGroupService: DispersedFormGroupService, cdr: ChangeDetectorRef) {
    super(elementRef, dispersedFormGroupService, cdr);
  }

  @Input() odataFunction!: (searchString: string) => Observable<EmployerSearchResult[]>;
  @Input() selectedEmployer: EmployerSearchResult | null | undefined;
  @Input() placeholder: string = $localize`Search by name or employer ID`;
  @Input() clearable: boolean = false;
  @Output() employerSelected = new EventEmitter<EmployerSearchResult | null>();

  public getValidators(): ValidatorFn[] {
    return this.required ? [Validators.required] : [];
  }

  public getAsyncValidators(): AsyncValidatorFn[] {
    return [];
  }

  public setBindingProperty(): void {
    this.setDataInBindingProperty(this.getMyValue());
  }

  private markAsDirty(): void {
    this?.getSelfAsFormItem()?.markAsDirty();
  }

  public ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    if (changes.selectedEmployer) {
      this.handleSelectedEmployerChange(this.selectedEmployer);
    }
  }

  private handleSelectedEmployerChange(selectedEmployer: EmployerSearchResult | null | undefined): void {
    this.handleInputChange(selectedEmployer);
    this.getSelfAsFormItem()?.patchValue(selectedEmployer);
    this.markAsDirty();
  }

  // TODO: SPP-913 Connect this component to a shimmer cell if required
  protected override checkParentElement(): void {}
}
