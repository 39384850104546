<div class="header-container">
  <div class="header-row row">
    <div class="col-xl-8 col-md-6 col-sm-4">
      <div *ngIf="title !== ''" class="header-text">
        {{ title }}
      </div>
    </div>
    <div class="col-xl-4 col-md-6 col-sm-8">
      <div class="button-container">
        <lib-reactive-form-group class="w-100" [applyDisabledStylesToAllElements]="true" [includeIconWithError]="true">
          <lib-reactive-form-text [inputName]="'search'" placeholder="Search" i18n-placeholder>
          </lib-reactive-form-text>
        </lib-reactive-form-group>
        <lib-button-neutral
          [iconSrc]="'assets/icons/dark/List.svg'"
          (buttonClicked)="rearrangeButtonClicked.emit()"
          i18n>
          Rearrange
        </lib-button-neutral>
      </div>
    </div>
  </div>
</div>
