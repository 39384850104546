<div class="auth-flow-subtext">
  <div class="header">{{ viewModel.authFlowTitle$ | async }}</div>
  <div class="subtext">{{ viewModel.authFlowSubtext$ | async }}</div>
</div>

<lib-reactive-form-group
  #form
  [bindTo]="req"
  [applyDisabledStylesToAllElements]="true"
  [includeIconWithError]="true"
  (formSubmitted$)="formSubmitted(req)"
  [customErrorMessageMap]="viewModel.customErrorMap">
  <lib-reactive-form-email
    [inputName]="'disabled-email'"
    label="Email"
    i18n-label
    [bindingProperty]="'email'"
    [required]="true"
    [placeholder]="''"
    [showRequiredAstrix]="false"
    [disabled]="true">
  </lib-reactive-form-email>
  <lib-reactive-form-password
    [inputName]="'password'"
    [required]="true"
    [creatingOrUpdatingPassword]="true"
    [placeholder]="''"
    [hintText]="passwordHintText"
    label="New Password"
    i18n-label
    [showRequiredAstrix]="false"
    [bindingProperty]="'password'">
  </lib-reactive-form-password>

  <lib-reactive-form-password
    [inputName]="'confirmPassword'"
    [required]="true"
    [placeholder]="''"
    [confirmPassword]="true"
    [showRequiredAstrix]="false"
    label="Confirm Password"
    i18n-label
    [bindingProperty]="'confirmPassword'">
  </lib-reactive-form-password>
  <lib-reactive-form-checkboxes
    [bindingProperty]="'eulaAccepted'"
    [label]="'eula'"
    [hideLabel]="true"
    [customValidators]="[viewModel.eulaValidatorDirective]"
    [hideBulkCheckbox]="true"
    [customValueParser]="viewModel.customValueParser"
    [inputName]="'eulaAccepted'">
    <div
      class="internal-user-eula-container"
      [ngClass]="{
        'mt-2': (form.formSubmitAttempt$ | async) && form.reactiveFormGroup.controls.confirmPassword.errors
      }">
      <lib-reactive-form-checkbox
        [inputName]="'eula'"
        (valueChanged)="setEulaChecked($event)"
        [selectedValue]="true"
        [notSelectedValue]="false">
        <span class="non-terms-label" i18n>I accept the</span>
      </lib-reactive-form-checkbox>
      <span (click)="openEulaModal()" class="terms-label" i18n> Terms and Conditions</span>
    </div>
  </lib-reactive-form-checkboxes>
</lib-reactive-form-group>

<lib-button-primary
  class="set-password"
  i18n
  [ngClass]="{ 'mt-4': (form.formSubmitAttempt$ | async) && !eulaChecked }"
  (buttonClicked)="form.submitForm()">
  {{ 'Set New Password' }}
</lib-button-primary>
