import { MemberUser } from '../../dto/member-user';
import { SubmissionRequest } from './submission-request';

export class UpdatePhoneNumberRequest extends SubmissionRequest {
  public primaryPhoneNumber: string;
  public primaryPhoneNumberCountryCode?: string;
  public secondaryPhoneNumber: string;
  public secondaryPhoneNumberCountryCode?: string;

  constructor(user: MemberUser) {
    super();
    this.memberId = user.id;
    this.primaryPhoneNumber = user.primaryPhoneNumber;
    this.primaryPhoneNumberCountryCode = user.primaryPhoneNumberCountryCode;
    this.secondaryPhoneNumber = user.secondaryPhoneNumber;
    this.secondaryPhoneNumberCountryCode = user.secondaryPhoneNumberCountryCode;
  }
}
