import { BaseDomainModel } from '../models/base/base-domain-model';
import { inject, Injectable } from '@angular/core';
import { AlertsAPI } from '../api/alerts-api';
import { BehaviorSubject, combineLatest, Observable, throwError } from 'rxjs';
import { Alert } from '../models/alerts/dto/alert';
import { ODataQueryOptions } from '../models/shared/odata-query-options';
import { ODataResponse } from '../models/protocols/odata-response';
import { catchError, map, tap } from 'rxjs/operators';
import { TypeService } from '../services/type/type-service';
import { CreateAlertRequest } from '../models/alerts/requests/create-alert-request';
import { HttpResponse } from '@angular/common/http';
import { AlertTypeEnum } from '../models/alerts/enum/alert-type.enum';
import { DefaultCacheKey } from '../models/enum/shared/default-cache-key.enum';
import { CacheService } from '../services/cache-service';
import { DismissedAlerts } from '../models/alerts/dto/dismissed-alerts';

// Provided by Logged In Scope
@Injectable()
export class AlertsDomainModel extends BaseDomainModel {
  constructor(private alertsAPI: AlertsAPI) {
    super();
    this.fetchCachedDismissedAlerts();
  }

  private typeService = inject(TypeService);
  private cacheService = inject(CacheService);

  private _alerts = new BehaviorSubject<Alert[] | null>(null);
  public alerts$ = this._alerts as Observable<Alert[]>;

  private _alertsTotalCount = new BehaviorSubject<number | undefined>(undefined);
  public alertsTotalCount$ = this._alertsTotalCount as Observable<number | undefined>;

  private _dismissedAlertIds = new BehaviorSubject<number[]>([]);

  private _activeAlerts = new BehaviorSubject<Alert[] | null>(null);
  public activeBannerAlerts$ = combineLatest([this._activeAlerts, this._dismissedAlertIds]).pipe(
    map(([activeAlerts, dismissedAlertIds]) => {
      return activeAlerts?.filter(a => !dismissedAlertIds.includes(a.id) && a.typeId === AlertTypeEnum.Banner);
    })
  );
  public activeCardAlerts$ = combineLatest([this._activeAlerts, this._dismissedAlertIds]).pipe(
    map(([activeAlerts, dismissedAlertIds]) => {
      return activeAlerts?.filter(a => !dismissedAlertIds.includes(a.id) && a.typeId === AlertTypeEnum.Card);
    })
  );

  public alertTypes$ = this.typeService.alertTypes$;

  private fetchCachedDismissedAlerts() {
    const dismissedAlertIds =
      this.cacheService.getCachedObject(DismissedAlerts, DefaultCacheKey.DismissedAlerts, false)?.alertIds ?? [];
    this._dismissedAlertIds.next(dismissedAlertIds);
  }

  public getAlerts(oDataQueryOptions: ODataQueryOptions): Observable<ODataResponse<Alert>> {
    return this.alertsAPI.getAlerts(oDataQueryOptions).pipe(
      tap(odataRes => this._alertsTotalCount.next(odataRes['@odata.count'])),
      tap(odataRes => this._alerts.next(odataRes.value)),
      catchError(err => {
        this._alerts.next([]);
        this._alertsTotalCount.next(0);
        return throwError(() => err);
      })
    );
  }

  public getActiveAlerts(): Observable<Alert[]> {
    return this.alertsAPI.getActiveAlerts().pipe(
      map(res => res.value),
      tap(activeAlerts => {
        this._activeAlerts.next(activeAlerts);
      })
    );
  }

  public createAlert(req: CreateAlertRequest): Observable<Alert> {
    return this.alertsAPI.createAlert(req);
  }

  public getAlertById(id: string): Observable<Alert> {
    return this.alertsAPI.getAlertById(id);
  }

  public updateAlert(al: Alert): Observable<Alert> {
    return this.alertsAPI.updateAlert(al);
  }

  public deleteAlert(id: string): Observable<HttpResponse<any>> {
    return this.alertsAPI.deleteAlert(id);
  }

  public dismissAlert(id: number) {
    const dismissedAlertIds =
      this.cacheService.getCachedObject(DismissedAlerts, DefaultCacheKey.DismissedAlerts, false)?.alertIds ?? [];
    if (!dismissedAlertIds.includes(id)) {
      dismissedAlertIds.push(id);
    }
    const dismissedAlerts = new DismissedAlerts();
    dismissedAlerts.alertIds = dismissedAlertIds;
    this.cacheService.cacheObject(DefaultCacheKey.DismissedAlerts, dismissedAlerts);
    this._dismissedAlertIds.next(dismissedAlertIds);
  }
}
