import { Component, inject, Injector, ViewChild } from '@angular/core';
import { BaseComponent } from 'src/app/models/base/base-component';
import { AccessedByPortalType } from '../../../../../interfaces/accessed-by-portal-type';
import { RequiredPermissions } from '../../../../../interfaces/required-permissions';
import { PortalType } from '../../../../../models/enum/shared/portal-type';
import { AlertDetailsViewModel } from './alert-details-view-model';
import { Router } from '@angular/router';
import { StringExtensions } from '../../../../../utils/string.extensions';
import { NgbDate, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertTypeEnum } from '../../../../../models/alerts/enum/alert-type.enum';
import { combineLatest, Observable, of } from 'rxjs';
import { Alert } from '../../../../../models/alerts/dto/alert';
import { map, switchMap } from 'rxjs/operators';
import { ReactiveFormGroupComponent, ReactiveFormSwitchComponent } from '@csspension/reactive-form';
import { DateValidDirective } from '../../../../shared/directives/date-valid-directive.directive';
import { StartEndDateValidatorDirective } from '../../../../shared/directives/start-end-date-validator.directive';
import { Breadcrumb } from '../../../../../models/shared/stylesheet/breadcrumb';
import { AlertStatusStringEnum } from '../../../../../models/alerts/enum/alert-status.enum';
import { ModalDeleteAlert } from '../../../../../modals/modal-delete-alert';
import { ComponentCanDeactivate } from '../../../../../models/protocols/component-can-deactivate';
import { ConfirmationOptions } from '../../../../../models/shared/stylesheet/confirmation-options';
import { ModalConfirmation } from '../../../../../modals/modal-confirmation';
import { PortalTypeIdEnum } from '../../../../../models/enum/shared/portal-type-id.enum';

export const MAX_ALERT_TITLE_LENGTH = 80;
export const MAX_ALERT_DESCRIPTION_LENGTH = 200;
export const MAX_ALERT_BUTTON_LENGTH = 40;

@Component({
  selector: 'app-alert-details',
  templateUrl: './alert-details.component.html',
  styleUrls: ['./alert-details.component.scss'],
  providers: [AlertDetailsViewModel]
})
export class AlertDetailsComponent
  extends BaseComponent
  implements AccessedByPortalType, RequiredPermissions, ComponentCanDeactivate
{
  constructor() {
    super();
  }

  @ViewChild('dismissableSwitch') dismissableSwitch!: ReactiveFormSwitchComponent;
  @ViewChild('form') form!: ReactiveFormGroupComponent;

  protected viewModel = inject(AlertDetailsViewModel);
  private router = inject(Router);
  protected ngbModal = inject(NgbModal);
  protected injector = inject(Injector);

  public loadingOpts$ = this.viewModel.loadingOpts$;
  public isCreatingAlert$ = of(false);

  public alert$ = this.viewModel.alert$;
  public showStartDatePicker = false;
  public showEndDatePicker = false;
  public maxAlertTitleLength = MAX_ALERT_TITLE_LENGTH;
  public maxAlertDescriptionLength = MAX_ALERT_DESCRIPTION_LENGTH;
  public maxAlertButtonLength = MAX_ALERT_BUTTON_LENGTH;
  public isEditingAlert$ = this.viewModel.isEditingAlert$;

  public validDateDirective = new DateValidDirective();
  public startEndDateDirective = new StartEndDateValidatorDirective();
  public dateErrorMap: Map<string, string> = new Map()
    .set('validDate', $localize`Must enter a date that follows the dd / mm / yyyy format`)
    .set('validStartEndDate', $localize`End date must be after start date`);

  public dateMask = this.viewModel.dateMask;
  public selectedStartDate$ = this.viewModel.selectedStartDate$;
  public selectedEndDate$ = this.viewModel.selectedEndDate$;
  public datePickerStartDate$ = this.viewModel.datePickerStartDate$;
  public datePickerEndDate$ = this.viewModel.datePickerEndDate$;
  public isCardTypeAlert$ = this.viewModel.isCardTypeAlert$;
  public cardRadioButtons$ = this.viewModel.cardRadioButtons$;
  public bannerRadioButtons$ = this.viewModel.bannerRadioButtons$;
  public pageTitle$ = of($localize`Alert Details`);
  public actionButtonText$ = of($localize`Publish Changes`);
  public permissionMap$ = this.viewModel.permissionMap$;
  public canEditAlert$ = this.viewModel.canEditAlert$;
  public statusPill$ = this.viewModel.statusPill$;
  public alertStartDate$ = this.viewModel.alertStartDate$;
  public alertEndDate$ = this.viewModel.alertEndDate$;
  public alertLastUpdatedDate$ = this.viewModel.alertLastUpdatedDate$;
  public previewLoading$ = this.viewModel.previewLoading$;
  public includeButton$ = this.viewModel.includeButton$;

  public canDeleteAlert$ = combineLatest([this.alert$, this.canEditAlert$]).pipe(
    map(([alert, canEdit]) => {
      return canEdit && !!alert?.id && alert.status !== AlertStatusStringEnum.Past;
    })
  );

  public isPastAlert$ = this.alert$.pipe(map(a => a?.status === AlertStatusStringEnum.Past));

  public alertPreview$ = this.viewModel.alertPreview$;
  public showAlertPreview$ = this.viewModel.showAlertPreview$;

  public showCardAlertPreview$ = combineLatest([this.showAlertPreview$, this.isCardTypeAlert$]).pipe(
    map(([show, isCard]) => show && isCard)
  );

  public showBannerAlertPreview$ = combineLatest([this.showAlertPreview$, this.isCardTypeAlert$]).pipe(
    map(([show, isCard]) => show && !isCard)
  );

  public breadcrumbs$ = combineLatest([this.alert$, this.isCreatingAlert$]).pipe(
    map(([alert, isCreating]) => {
      if (!!alert) {
        const breadcrumbs: Breadcrumb[] = [];
        breadcrumbs.push(new Breadcrumb($localize`Alerts`, '/settings/alerts'));
        if (isCreating) {
          breadcrumbs.push(new Breadcrumb($localize`Create an Alert`, '/settings/create-alert'));
        } else {
          breadcrumbs.push(new Breadcrumb($localize`Alert Details`, `/settings/alerts/${alert?.id}`));
        }

        return breadcrumbs;
      }
    })
  );

  public alertIsActive$ = this.alert$.pipe(map(a => a.status === AlertStatusStringEnum.Active));

  goBack(): void {
    this.router.navigate(['/settings/alerts']).then();
  }

  public toggleStartDatePicker(): void {
    this.showStartDatePicker = !this.showStartDatePicker;
    this.showEndDatePicker = false;
  }

  public toggleEndDatePicker(): void {
    this.showEndDatePicker = !this.showEndDatePicker;
    this.showStartDatePicker = false;
  }

  public hideStartDatePicker(): void {
    this.showStartDatePicker = false;
  }

  public hideEndDatePicker(): void {
    this.showEndDatePicker = false;
  }

  public setEndDate(selDate: NgbDate[]): void {
    this.viewModel.setEndDate(selDate);
    this.hideEndDatePicker();
  }

  public setStartDate(selDate: NgbDate[]): void {
    this.viewModel.setStartDate(selDate);
    this.hideStartDatePicker();
  }

  public includeButtonChanged(value: boolean): void {
    this.viewModel.setIncludeButton(value);
  }

  public updatePreview(req: Alert): void {
    const switchValue = this.dismissableSwitch.getMyValue();
    const alertFromCapture = Object.assign(new Alert(), req);
    alertFromCapture.dismissable = switchValue;
    this.viewModel.updatePreview(alertFromCapture);
    this.viewModel.setAlertFormDirty(this.form.reactiveFormGroup.dirty);
  }

  public openDeleteModal(): void {
    this.alert$.once(alert => {
      ModalDeleteAlert.open(this.ngbModal, this.injector).then(result => {
        if (result) {
          this.viewModel.deleteAlert(alert.id.toString());
        }
      });
    });
  }

  public setAlertType(type: AlertTypeEnum): void {
    this.alert$.once(alert => {
      if (alert.typeId !== type && !!alert?.id) {
        const opts = new ConfirmationOptions();
        let styleType: string;
        if (type === AlertTypeEnum.Card) {
          styleType = $localize`Card`;
        } else {
          styleType = $localize`Banner`;
        }
        opts.title = $localize`Changes to Message Content`;
        opts.bodyText = $localize`Changing the message style to ${styleType} will impact the button fields and
        description. Are you sure you want to change the style?`;
        opts.continueText = $localize`Change Message Style`;
        opts.cancelText = $localize`Cancel`;
        ModalConfirmation.open(this.ngbModal, this.injector, opts, result => {
          if (result) {
            this.onAlertTypeUpdated(alert, type);
          } else {
            const oldAlertType = type === AlertTypeEnum.Card ? AlertTypeEnum.Banner : AlertTypeEnum.Card;
            this.viewModel.setAlert({ ...alert, typeId: oldAlertType } as Alert);
          }
        });
      } else {
        this.onAlertTypeUpdated(alert, type);
      }
    });
  }

  private onAlertTypeUpdated(alert: Alert, type: AlertTypeEnum): void {
    this.viewModel.setAlertType(type);
    if (type === AlertTypeEnum.Card) {
      this.setCardAlert(alert);
    }
    this.viewModel.setAlertFormDirty(true);
  }

  private setCardAlert(alert: Alert) {
    this.viewModel.setIncludeButton(true);
    alert.includeButton = true;
    this.viewModel.setAlert(alert);
  }

  public toggleShowAlertPreview(): void {
    this.viewModel.toggleShowAlertPreview();
  }

  public toggleIsEditingAlert(): void {
    this.viewModel.toggleIsEditingAlert();
  }

  public cancelClicked(): void {
    this.isCreatingAlert$.once(isCreating => {
      if (isCreating) {
        this.goBack();
      } else {
        this.toggleIsEditingAlert();
      }
    });
  }

  public submitForm(alert: Alert): void {
    this.viewModel.createAlert(alert);
  }

  public previewValueChanged(): void {
    this.viewModel.setAlertPreviewPristine(false);
  }

  public getAllowedPortalTypes(): PortalType[] {
    return [PortalType.Internal];
  }

  public canDeactivate(): boolean | Promise<boolean> | Observable<boolean> {
    return combineLatest([this.viewModel.alertFormDirty$, this.viewModel.alert$]).pipe(
      switchMap(([dirty, alert]) => {
        const title = alert?.id
          ? $localize`Are you sure you want to discard your changes?`
          : $localize`Are you sure you want to cancel this alert?`;
        const bodyText = alert?.id
          ? $localize`By cancelling, you will lose any changes you’ve made to the alert and the updates
          will not be published.`
          : $localize`Any information entered will be discarded and you will be returned to the Alerts Table.`;
        if (dirty) {
          const opts = new ConfirmationOptions();
          opts.title = title;
          opts.bodyText = bodyText;
          opts.continueText = $localize`Cancel Changes`;
          opts.cancelText = $localize`Close`;
          return ModalConfirmation.open(this.ngbModal, this.injector, opts, undefined, true) as Observable<boolean>;
        } else {
          return of(true);
        }
      })
    );
  }

  getRequiredPermissions(): number[] {
    return [44];
  }

  setupBindings(): void {}

  setupViews(): void {}

  protected readonly StringExtensions = StringExtensions;
  protected readonly AlertTypeEnum = AlertTypeEnum;
  protected readonly PortalTypeIdEnum = PortalTypeIdEnum;
}
