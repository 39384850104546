<div class="d-flex">
  <app-internal-user-auth-wrapper
    [loadingOpts]="(loadingOpts$ | async) ?? undefined"
    *ngIf="portalService.isInternal$ | async">
    <!-- Internal Users Auth Flows -->
    <app-internal-user-auth (loadingOpts)="setLoadingOpts($event)"></app-internal-user-auth>
  </app-internal-user-auth-wrapper>
  <app-auth-wrapper [loadingOpts]="(loadingOpts$ | async) ?? undefined" *ngIf="!(portalService.isInternal$ | async)">
    <ng-container *ngIf="portalService.isMember$ | async">
      <!-- Member Auth Flows -->
      <app-member-auth (loadingOpts)="setLoadingOpts($event)"></app-member-auth>
    </ng-container>
    <ng-container *ngIf="portalService.isEmployer$ | async">
      <!-- Employer Auth Flows -->
      <app-employer-auth (loadingOpts)="setLoadingOpts($event)"></app-employer-auth>
    </ng-container>
  </app-auth-wrapper>
</div>

<div class="software-version">
  {{ versionString$ | async }}
</div>
