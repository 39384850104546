<div class="auth-title-text">
  {{ viewModel.authFlowTitle$ | async }}
</div>

<div class="auth-flow-subtext">
  {{ viewModel.authFlowSubtext$ | async }}
</div>

<lib-reactive-form-group
  #form
  [bindTo]="req"
  (formSubmitted$)="formSubmitted($event)"
  [applyDisabledStylesToAllElements]="true"
  [includeIconWithError]="true"
  [customErrorMessageMap]="viewModel.customErrorMap">
  <lib-reactive-form-email
    [inputName]="'email'"
    [placeholder]="''"
    label="Email"
    i18n-label
    [bindingProperty]="'email'"
    [required]="true"
    [hintText]="hintText"
    [showRequiredAstrix]="false">
  </lib-reactive-form-email>

  <lib-reactive-form-password
    [inputName]="'password'"
    [required]="true"
    [creatingOrUpdatingPassword]="true"
    label="New Password"
    i18n-label
    [placeholder]="''"
    [hintText]="passwordHintText"
    [bindingProperty]="'password'"
    [showRequiredAstrix]="false">
  </lib-reactive-form-password>

  <lib-reactive-form-password
    [inputName]="'confirmPassword'"
    [required]="true"
    [confirmPassword]="true"
    label="Confirm Password"
    i18n-label
    [placeholder]="''"
    [bindingProperty]="'confirmPassword'"
    [showRequiredAstrix]="false">
  </lib-reactive-form-password>

  <lib-reactive-form-checkboxes
    [ngClass]="{ 'mt-2': (form.formSubmitAttempt$ | async) && form.reactiveFormGroup.controls.confirmPassword.errors }"
    [bindingProperty]="'eulaAccepted'"
    class="d-flex"
    [label]="'eula'"
    [hideLabel]="true"
    [customValidators]="[viewModel.eulaValidatorDirective]"
    [hideBulkCheckbox]="true"
    [customValueParser]="viewModel.customValueParser"
    [inputName]="'eulaAccepted'">
    <div class="eula-container">
      <lib-reactive-form-checkbox
        [inputName]="'eula'"
        (valueChanged)="setEulaChecked($event)"
        [selectedValue]="true"
        [notSelectedValue]="false">
        <span class="non-terms-label"><ng-container i18n>I accept the</ng-container> </span>
      </lib-reactive-form-checkbox>
      <span (click)="viewModel.openEulaModal()" class="terms-label" i18n> Terms and Conditions</span>
    </div>
  </lib-reactive-form-checkboxes>
</lib-reactive-form-group>
<div
  class="d-flex justify-content-center align-items-center"
  i18n
  [ngClass]="{ 'mt-4': (form.formSubmitAttempt$ | async) && form.reactiveFormGroup.controls?.eulaAccepted?.errors }">
  <lib-button-primary [style.width.%]="100" (buttonClicked)="form.submitForm()">
    {{ 'Continue' }}
  </lib-button-primary>
</div>
<div class="member-support-button-container" i18n>
  {{ 'Having trouble?' }}
  <button class="paddingless-text-button support-text mt-2" (click)="viewModel.openContactPage()">
    {{ 'Click here for support' }}
  </button>
</div>
