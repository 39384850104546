<div class="d-flex">
  <div
    *ngFor="let step of steps; let i = index"
    class="step-button me-4"
    [class.step-disabled]="i > selectedIndex"
    [class.step-active]="selected === step"
    [class.step-completed]="i < selectedIndex">
    <img
      class="step-check-icon"
      src="assets/icons/transparent-backgrounds/GreenCheckCircle.svg"
      alt="completed"
      i18n-alt />
    <div class="step-number">{{ i + 1 }}</div>
    <div class="step-title ms-2">{{ step.label }}</div>
  </div>
</div>

<div [style.margin-top.rem]="2">
  <ng-container *ngTemplateOutlet="selected?.content"></ng-container>
</div>
