<div class="auth-title-text">
  {{ viewModel.authFlowTitle$ | async }}
</div>

<div class="auth-flow-subtext" i18n>
  <div class="subtext">
    {{ 'A 6-Digit Code has been sent to' }}
    <span>{{ userEmail$ | async }}</span>
    {{
      '. Please allow 5-10 minutes for this message to arrive. If you don’t see an email from us, you may need to check your spam folder.'
    }}
  </div>
  <div class="subtext mt-3">
    {{ 'Enter your code below to verify your email address and finalize your account creation.' }}
  </div>
</div>

<lib-reactive-form-group
  #form
  [bindTo]="req$ | async"
  [applyDisabledStylesToAllElements]="true"
  [includeIconWithError]="true"
  (formSubmitted$)="formSubmitted($event)">
  <lib-reactive-form-text
    [inputName]="'verificationCode'"
    label="Verification Code"
    i18n-label
    [bindingProperty]="'code'"
    [required]="true"
    [showRequiredAstrix]="false"
    [placeholder]="''">
  </lib-reactive-form-text>
</lib-reactive-form-group>

<div class="action-button-container">
  <lib-button-primary [style.width.%]="100" (buttonClicked)="form.submitForm()">
    <ng-container i18n>Verify Account</ng-container>
  </lib-button-primary>
</div>
<div class="member-support-button-container">
  <ng-container i18n>Having trouble ?</ng-container>
  <button class="paddingless-text-button support-text mt-2" (click)="viewModel.openContactPage()">
    <ng-container i18n>Click here for support</ng-container>
  </button>
</div>
