import { AbstractControl, NG_VALIDATORS } from '@angular/forms';
import { Directive, Inject } from '@angular/core';

@Directive({
  selector: '[appRequiredCustomMessageValidatorDirective]',
  providers: [{ provide: NG_VALIDATORS, useExisting: RequiredCustomMessageValidatorDirective, multi: true }]
})
export class RequiredCustomMessageValidatorDirective {
  constructor(@Inject('requiredCustomMessageKey') public requiredCustomMessageKey: string) {
    this.requiredCustomMessageKey = requiredCustomMessageKey;
  }

  validate(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value || control.value.length === 0) return { [this.requiredCustomMessageKey]: true };
    return null;
  }
}
