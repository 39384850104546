<!-- TODO: Update this form when design is finalized -->
<app-base-action-modal
  title="Personal Information"
  i18n-title
  primaryLabel="Continue"
  i18n-primaryLabel
  [hideSecondary]="true"
  [ignoreEscape]="true"
  [hideClose]="true"
  (primaryClicked)="form.submitForm(); coopForm?.submitForm()">
  <app-loading *ngIf="viewModel.isLoading$ | async" [options]="(viewModel.loadingOpts$ | async) ?? undefined">
  </app-loading>
  <lib-reactive-form-group
    #form
    (formSubmitted$)="formSubmitted($event)"
    [bindTo]="req$ | async"
    [applyDisabledStylesToAllElements]="true"
    [includeIconWithError]="true">
    <lib-reactive-form-column-layout [nColumns]="(screenService.isMobile$ | async) ? 1 : 2">
      <lib-reactive-form-text
        [inputName]="'firstName'"
        label="Given Name(s)"
        i18n-label
        [bindingProperty]="'firstName'"
        [required]="true">
      </lib-reactive-form-text>
      <lib-reactive-form-text
        [inputName]="'lastName'"
        label="Last Name"
        i18n-label
        [bindingProperty]="'lastName'"
        [required]="true">
      </lib-reactive-form-text>
    </lib-reactive-form-column-layout>
    <lib-reactive-form-column-layout [nColumns]="(screenService.isMobile$ | async) ? 1 : 2">
      <lib-reactive-form-text
        [inputName]="'address1'"
        [required]="true"
        label="Street or P.O. Box Number"
        i18n-label
        [bindingProperty]="'address.addressLine1'">
      </lib-reactive-form-text>
      <lib-reactive-form-text
        [inputName]="'city'"
        label="City or Town"
        i18n-label
        [required]="true"
        [bindingProperty]="'address.city'">
      </lib-reactive-form-text>
    </lib-reactive-form-column-layout>
    <lib-reactive-form-column-layout [nColumns]="(screenService.isMobile$ | async) ? 1 : 2">
      <lib-reactive-form-drop-down
        [inputName]="'country'"
        label="Country"
        i18n-label
        [inlineLabel]="false"
        [required]="true"
        [dropdowns]="(viewModel.countryDropdowns$ | async) ?? undefined"
        [bindingProperty]="'address.countryId'"
        [programmaticallyChangeValue]="viewModel.selectedCountryId$ | async"
        (valueChanged)="viewModel.countryChanged($event)">
      </lib-reactive-form-drop-down>
      <lib-reactive-form-drop-down
        [inputName]="'province'"
        label="Province / State"
        i18n-label
        [inlineLabel]="false"
        placeholder="Select Province"
        i18n-placeholder
        [required]="true"
        [dropdowns]="(viewModel.stateDropdowns$ | async) ?? undefined"
        [bindingProperty]="'address.stateId'">
      </lib-reactive-form-drop-down>
    </lib-reactive-form-column-layout>
    <lib-reactive-form-column-layout [nColumns]="(screenService.isMobile$ | async) ? 1 : 2">
      <lib-reactive-form-text
        [inputName]="'postal'"
        [required]="true"
        label="Postal Code"
        i18n-label
        [bindingProperty]="'address.postalCode'">
      </lib-reactive-form-text>
      <lib-reactive-form-phone-country-code
        [bindingProperty]="'primaryPhoneNumber'"
        [countryCodeBindingProperty]="'primaryPhoneNumberCountryCode'"
        [hintText]="'(000) 000-0000'"
        label="Phone"
        i18n-label
        [countryCodes]="(countryCodes$ | async) ?? []"
        [required]="true"
        [inputName]="'primaryPhoneNumber'"></lib-reactive-form-phone-country-code>
    </lib-reactive-form-column-layout>
  </lib-reactive-form-group>
</app-base-action-modal>
