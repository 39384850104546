<div class="auth-title-text-pb">{{ loginTitle$ | async }}</div>

<lib-reactive-form-group
  #form
  [bindTo]="req$ | async"
  [applyDisabledStylesToAllElements]="true"
  [includeIconWithError]="true"
  (formSubmitted$)="formSubmitted($event)">
  <lib-reactive-form-email
    [inputName]="'email'"
    label="Email"
    i18n-label
    [bindingProperty]="'email'"
    [required]="true"
    [placeholder]="''">
  </lib-reactive-form-email>

  <lib-reactive-form-password
    [inputName]="'password'"
    [required]="true"
    label="Password"
    i18n-label
    [placeholder]="''"
    (keydown.enter)="form.submitForm()"
    [bindingProperty]="'password'">
  </lib-reactive-form-password>
</lib-reactive-form-group>

<div class="action-button-container">
  <button class="paddingless-text-button" i18n (click)="secondaryButtonPressed()">
    {{ 'Forgot your password ?' }}
  </button>
  <lib-button-primary i18n [style.width.%]="100" (buttonClicked)="form.submitForm()">
    {{ 'Log In' }}
  </lib-button-primary>
</div>
