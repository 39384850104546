<div class="auth-flow-title">
  {{ viewModel.authFlowTitle$ | async }}
</div>

<div class="auth-flow-subtext">
  <div>
    {{ 'You’re almost there! We sent an email with a verification code to' }}
    <span>{{ viewModel.newMemberEmail$ | async }}</span>
    <ng-container i18n>. Please allow 5-10 minutes for this message to arrive.</ng-container>
  </div>
  <div class="mt-3">
    <ng-container i18n>
      Click on the link in the email to complete your account setup. If you don’t see an email from us,
    </ng-container>
    <span class="auth-highlighted-text">
      <ng-container i18n>you may need to check your spam folder.</ng-container>
    </span>
  </div>
  <div class="mt-3">
    <ng-container i18n>Still cant find the email?</ng-container>
  </div>
</div>

<div class="action-button-container">
  <lib-button-primary [style.width.%]="100" i18n>Resend Email</lib-button-primary>
</div>
<div class="member-support-button-container">
  <ng-container i18n>Having trouble?</ng-container>
  <button class="paddingless-text-button support-text mt-2" (click)="viewModel.openContactPage()">
    <ng-container i18n>Click here for support</ng-container>
  </button>
</div>
