import { Component, inject } from '@angular/core';
import { BaseModalComponent } from '../../../../models/base/base-modal.component';
import { ChangeUserEmailModalViewModel } from './change-user-email-modal-view-model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseUser } from '../../../../models/base/base-user';
import { ChangeEmailRequest } from '../../../../models/account/requests/change-email-request';
import { MatchOtherInputValidatorDirective } from '@csspension/reactive-form';

@Component({
  selector: 'app-change-user-email-modal',
  templateUrl: './change-user-email-modal.component.html',
  styleUrls: [
    './change-user-email-modal.component.scss',
    '../../../profile/modals/change-email-modal/change-email-modal.component.scss'
  ],
  providers: [ChangeUserEmailModalViewModel]
})
export class ChangeUserEmailModalComponent extends BaseModalComponent {
  public ignoreEscape: boolean = false;

  constructor(protected activeModal: NgbActiveModal) {
    super(activeModal);
  }

  private viewModel = inject(ChangeUserEmailModalViewModel);

  public loadingOpts$ = this.viewModel.loadingOpts$;
  public isLoading$ = this.viewModel.isLoading$;
  public changeEmailReq$ = this.viewModel.changeEmailReq$;

  public customErrorMap: Map<string, string> = new Map().set(
    MatchOtherInputValidatorDirective.key,
    $localize`Emails must match`
  );

  public closeModal(): void {
    this.viewModel.closeModal();
  }

  public formSubmitted(req: ChangeEmailRequest): void {
    this.viewModel.formSubmitted(req);
  }

  public setupBindings(): void {
    this.viewModel.dismissModalSubject.subscribeWhileAlive({
      owner: this,
      next: close => {
        if (close instanceof BaseUser) {
          this.close(close);
        } else {
          this.close(false);
        }
      }
    });
  }

  public setupViews(): void {}

  public setUser<T extends BaseUser>(user: T): void {
    this.viewModel.setUser<T>(user);
  }

  public setCurrentEmail(email: string): void {
    this.viewModel.setCurrentEmail(email);
  }
}
