import { Deserializable } from '../protocols/deserializable';
import { NameChangeSubmission } from './name-change-submission';
import { SubmissionComment } from './submission-comment';
import { SubmissionTypeEnum } from '../account/enum/submission-type-enum';
import { SubmissionAttachment } from './submission-attachment';
import { LookAheadItem } from '../../views/shared/components/search-with-look-ahead/look-ahead-list/look-ahead-item/protocol/look-ahead-item';
import { Searchable } from '../protocols/searchable';
import { SubmissionSubStatusEnum } from '../enum/shared/submission-sub-status.enum';
import { SubmissionStatusEnum } from '../enum/shared/submission-status.enum';
import { AddressChangeSubmission } from './address-change-submission';
import { PhoneNumberChangeSubmission } from './phone-number-change-submission';
import { SubmissionHistoryItem } from './submission-history-item';
import { Mentionable } from '../base/mentionable';
import { PersonalDetailsSubmission } from './personal-details-submission';
import { PortalTypeIdEnum } from '../enum/shared/portal-type-id.enum';
import * as moment from 'moment/moment';
import { SubmissionStatusPill } from '../shared/submission-status-pill';

export class MemberSubmission implements Deserializable {
  // TODO: Tidy this up once all submission types are added to this object
  public id!: number;
  public memberId!: number;
  public memberName!: string;
  public submissionTypeId!: SubmissionTypeEnum;
  public submissionTypeName!: string;
  public statusId!: number;
  public status!: string;
  public subStatusId!: number | null;
  public subStatus!: SubmissionSubStatusEnum;
  public comments!: SubmissionComment[];
  public owner!: SubmissionStakeholder;
  public ownerId!: number | null;
  public approver!: SubmissionStakeholder;
  public approverId!: number | null;
  public attachments!: SubmissionAttachment[];
  public history!: SubmissionHistoryItem[];
  public idPrefix!: string;
  public idSuffix!: string;
  public submittedDate!: string;
  public overdueDate!: string;
  public followUpDate!: string | null;
  public nameChangeSubmissionId!: string | null;
  public addressChangeSubmissionId!: string | null;
  public phoneNumberSubmissionId!: string | null;
  public personalDetailsSubmissionId!: string | null;
  public nameChangeSubmission!: NameChangeSubmission | null;
  public phoneNumberSubmission!: PhoneNumberChangeSubmission | null;
  public addressChangeSubmission!: AddressChangeSubmission | null;
  public personalDetailsSubmission!: PersonalDetailsSubmission | null;
  public reminder!: SubmissionReminder | null;
  public expirationDate!: Date | null;
  public lastStatusUpdateDate!: Date | null;
  public createdInPortalTypeId!: PortalTypeIdEnum;
  public completedDate!: Date | null;
  public createdDate!: Date | null;

  // Not From API (yet)
  public submissionId!: string;
  public formattedCreatedDate!: string;
  public formattedCompletedDate!: string;
  public formattedExpirationDate!: string;
  public statusPill!: SubmissionStatusPill;
  public expiringSoon!: boolean;

  public canChangeSubmissionOwner(): boolean {
    return (
      this.subStatus === SubmissionSubStatusEnum.Ready ||
      this.subStatus === SubmissionSubStatusEnum.PendingDocuments ||
      this.subStatus === SubmissionSubStatusEnum.Processing ||
      this.subStatus === SubmissionSubStatusEnum.MemberActionRequired ||
      this.subStatus === SubmissionSubStatusEnum.EmployerActionRequired ||
      this.subStatus === SubmissionSubStatusEnum.ReadyForApproval
    );
  }

  public canChangeSubmissionApprover(): boolean {
    return this.subStatus === SubmissionSubStatusEnum.ReadyForApproval;
  }

  public canUploadFiles(): boolean {
    return this.status === SubmissionStatusEnum.Submitted || this.status === SubmissionStatusEnum.InReview;
  }

  onDeserialize(): void {
    this.submissionId = this.buildSubmissionId();
    if (!!this.owner) {
      this.owner = window?.injector?.Deserialize?.instanceOf(SubmissionStakeholder, this.owner);
    }
    if (!!this.approver) {
      this.approver = window?.injector?.Deserialize?.instanceOf(SubmissionStakeholder, this.approver);
    }
    if (!!this.comments) {
      this.comments = window?.injector?.Deserialize?.arrayOf(SubmissionComment, this.comments);
    }
    if (!!this.reminder) {
      this.reminder = window?.injector?.Deserialize?.instanceOf(SubmissionReminder, this.reminder);
    }
    if (!!this.history) {
      this.history = window?.injector?.Deserialize?.arrayOf(SubmissionHistoryItem, this.history);
      this.history.sort((a, b) => b.eventDate?.getTime() - a.eventDate?.getTime());
    }
    if (!!this.expirationDate) {
      this.formattedExpirationDate = moment(this.expirationDate).format('MMMM D, YYYY');
      this.expiringSoon = moment(this.expirationDate).diff(moment(), 'days') <= 10;
    }
    if (!!this.createdDate) {
      this.formattedCreatedDate = moment(this.createdDate).format('MMMM D, YYYY');
    }
    if (!!this.completedDate) {
      this.formattedCompletedDate = moment(this.completedDate).format('DD / MM / YYYY');
    }
    if (!!this.status) {
      this.statusPill = new SubmissionStatusPill(this.id, this.status, this.statusId, this.status);
    }
    if (!!this.expirationDate) {
      this.expirationDate = new Date(this.expirationDate);
    }
    if (!!this.lastStatusUpdateDate) {
      this.lastStatusUpdateDate = new Date(this.lastStatusUpdateDate);
    }
    if (!!this.attachments) {
      this.attachments = window?.injector?.Deserialize?.arrayOf(SubmissionAttachment, this.attachments);
    }
  }

  private buildSubmissionId(): string {
    // Submission IDs should be 8 characters long, padded with leading zeros
    const paddedId = this.id.toString().padStart(8, '0');
    return `${this.idPrefix}-${paddedId}-${this.idSuffix}`;
  }
}

export class SubmissionStakeholder extends Mentionable implements LookAheadItem, Searchable {
  // Searchable
  public title!: string;
  public value: any;

  onDeserialize() {
    super.onDeserialize();
    this.title = this.fullName;
    this.value = this.id;
  }

  lookAheadDisabled(): boolean {
    return false;
  }

  lookAheadDisabledMessage(): string {
    return '';
  }
}

export class SubmissionReminder implements Deserializable {
  public id!: number;
  public internalUserId!: number;
  public date!: Date;

  onDeserialize() {
    if (!!this.date) {
      this.date = new Date(this.date);
    }
  }
}
