import { Component, inject, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ScreenService } from '../../../../services/screen-service.service';
import { MemberContactInfoViewModel } from './member-contact-info-view-model';
import { ReactiveFormGroupComponent } from '@csspension/reactive-form';
import { map } from 'rxjs/operators';
import { MemberOnboardingInfoRequest } from '../../../../models/account/requests/member-onboarding-info-request';
import { BaseModalComponent } from '../../../../models/base/base-modal.component';
import { TypeService } from '../../../../services/type/type-service';

@Component({
  selector: 'app-member-personal-info-modal',
  templateUrl: './member-personal-info-modal.component.html',
  styleUrls: ['./member-personal-info-modal.component.scss']
})
export class MemberPersonalInfoModalComponent extends BaseModalComponent {
  private typeService = inject(TypeService);

  public screenService = inject(ScreenService);
  public viewModel = inject(MemberContactInfoViewModel);
  public ignoreEscape = true;

  public req$ = this.viewModel.user$.pipe(
    map(u => {
      return new MemberOnboardingInfoRequest(u);
    })
  );

  public readonly countryCodes$ = this.typeService.countryCodes$;

  @ViewChild('form') form!: ReactiveFormGroupComponent;
  @ViewChild('coopForm') coopForm!: ReactiveFormGroupComponent;

  constructor(protected activeModal: NgbActiveModal) {
    super(activeModal);
  }

  setupBindings(): void {
    this.typeService.countryCodes$.once(() => {});
    this.viewModel.dismissModalSubject$.subscribeWhileAlive({
      owner: this,
      next: dismiss => {
        if (dismiss) this.close(true);
      }
    });
  }

  setupViews(): void {}

  formSubmitted(request: MemberOnboardingInfoRequest) {
    if (!request.primaryPhoneNumber) delete request.primaryPhoneNumberCountryCode;
    if (!request.secondaryPhoneNumber) delete request.secondaryPhoneNumberCountryCode;
    if (!request.addressId) delete request.addressId;
    this.viewModel.updateMemberOnboardingInformation(request);
  }

  // TODO: Implement
  coopFormSubmitted(event: any) {}
}
